<template>
  <v-col class="active-subscription">
    <v-row class="pa-3 pa-md-3 pb-0 pb-md-0" align="center" justify="space-between" no-gutters>
      <v-col class="col-12 active-subscription__bottom-border pb-3">
        <v-row align="start" justify="start" no-gutters>
          <v-col cols="auto">
            <h2 class="headline font-weight-bold mb-0">
              {{ $t('subscription.actual') }}
            </h2>
          </v-col>
          <v-col class="px-2">
            <v-btn color="primary" class="text-decoration-underline px-2" text @click="hideActiveSubscription">
              {{ $t(hideActiveSubscriptionPanel ? 'general.block.expand' : 'subscription.change') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-expand-transition>
        <v-col v-show="!hideActiveSubscriptionPanel" cols="12">
          <v-row align="center" justify="center" no-gutters>
            <v-col class="col-12 active-subscription__bottom-border">
              <v-row align="center" justify="center">
                <v-col
                  class="col-12 col-md-4 pa-10 text-center"
                  :class="{
                    'active-subscription__bottom-border': isMobileDevice,
                  }"
                >
                  <v-progress-circular
                    color="primary"
                    class="text-center"
                    :rotate="-90"
                    :size="225"
                    :width="40"
                    :value="documentsLeftPercentage"
                  >
                    <v-row align="center" no-gutters>
                      <v-col class="col-12 subtitle-2 grey--text">
                        {{ $t('general.remains') }}
                      </v-col>
                      <v-col class="col-12 subtitle-2 grey--text">
                        {{ $t('general.documents').toLowerCase() }}
                      </v-col>
                      <v-col class="col-12">
                        <h1 class="heading text-center black--text font-weight-bold mb-0">
                          {{ documentsLeft }}
                        </h1>
                      </v-col>
                    </v-row>
                  </v-progress-circular>
                </v-col>
                <v-col class="col-12 col-md-8 pa-5">
                  <v-row align="center" justify="center">
                    <v-col class="col-6">
                      <div class="grey--text">{{ $t('general.price') }}:</div>
                      <div class="">
                        <h2 class="headline font-weight-bold mb-0">
                          {{ pricePerMonth }}
                        </h2>
                      </div>
                    </v-col>
                    <v-col class="col-6">
                      <div class="grey--text">{{ $t('general.renewal_date') }}:</div>
                      <div class="">
                        <h2 class="headline font-weight-bold mb-0">
                          {{ formattedRenewalDate }}
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col class="col-6">
                      <div class="grey--text">{{ documentsPerPeriodTitle }}:</div>
                      <div class="">
                        <h2 class="headline font-weight-bold mb-0">
                          {{ documentsPerPeriod }}
                        </h2>
                      </div>
                    </v-col>
                    <v-col class="col-6">
                      <div class="grey--text">{{ $t('documents.currently_exhausted') }}:</div>
                      <div class="">
                        <h2 class="headline font-weight-bold mb-0">
                          {{ currentlyExhausted }}
                          <SHelp :message="`${$t('subscription.documents.info')}`" />
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="col-12 pt-1 text-right">
              <v-btn color="grey" class="text-decoration-underline px-2" :loading="canceling" text @click="cancel">
                {{ $t('payments.subscription.cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-expand-transition>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getFormattedDate } from '@/common/reusable/dateFunctions'

export default {
  name: 'ActiveSubscription',
  components: {
    SHelp: () => import(/* webpackChunkName: "signi-help" */ '@/common/components/SHelp'),
  },
  data() {
    return {
      canceling: false,
      hideActiveSubscriptionPanel: false,
    }
  },
  computed: {
    ...mapGetters({
      active: 'subscription/active',
      currentlyExhausted: 'subscription/currentlyExhausted',
      currentCurrency: 'currencies/currentCurrency',
      currentPackage: 'subscription/currentPackage',
      documentsLeft: 'subscription/documentsLeft',
      documentsPerPeriod: 'subscription/documentsPerPeriod',
      hasActiveSubscription: 'subscription/hasActiveSubscription',
      price: 'subscription/price',
      renewalDate: 'subscription/renewalDate',
    }),
    currency() {
      return this.currentCurrency(this.currentPackage.currency)
    },
    documentsLeftPercentage() {
      return (this.documentsLeft / this.documentsPerPeriod) * 100
    },
    formattedRenewalDate() {
      return getFormattedDate({
        date: this.renewalDate,
        format: 'DD.MM.YYYY',
      })
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile
    },
    subscriptionPeriod() {
      return 12 === this.currentPackage?.repeat_payment_months ? 'year' : 'month'
    },
    documentsPerPeriodTitle() {
      return this.$t(`documents.per_${this.subscriptionPeriod}`)
    },
    pricePerMonth() {
      return `${this.price}/${this.$t(`general.${this.subscriptionPeriod}`).toLowerCase()}`
    },
  },
  mounted() {
    if ('true' === this.$route.query?.showPackages) {
      this.hideActiveSubscription()
    }
  },
  methods: {
    ...mapActions({
      cancelSubscription: 'subscription/cancelSubscription',
    }),
    cancel() {
      this.canceling = true
      this.cancelSubscription()
        .then(() => {
          this.$notification.success(this.$t('payments.subscription.cancel.info'))
        })
        .catch(() => {
          this.$notification.error(this.$t('payments.subscription.cancel.failed'))
        })
        .finally(() => {
          this.canceling = false
        })
    },
    hideActiveSubscription() {
      this.$emit('on-change-subscription-package')
      this.hideActiveSubscriptionPanel = !this.hideActiveSubscriptionPanel
    },
  },
}
</script>

<style lang="scss" scoped>
.active-subscription {
  background-color: #ffffffff;
  border: 1px solid #d4dff0;
  border-radius: 8px;
  max-width: 972px;

  &__bottom-border {
    border-bottom: 1px solid #d4dff0;
  }
}
</style>

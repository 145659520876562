<template>
  <v-row class="subscription-packages-item pa-2 fill-height" align="stretch" justify="space-between" no-gutters>
    <template v-if="isMobileDevice">
      <v-col cols="12" style="border-bottom: 1px solid #d4dff0">
        <v-container fill-height>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12">
              <span class="subscription-packages-item__price--package-price title font-weight-bold">
                {{ $t('general.tailored') }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12">
        <v-row class="py-2" align="center" justify="space-between" no-gutters>
          <v-col class="pl-2">
            <div class="subtitle-2 grey--text">
              {{ $t('pricing.special_plan_info') }}
            </div>
          </v-col>

          <v-col cols="auto">
            <v-btn color="primary" @click="openSigniPricing">
              {{ $t('general.ask') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template v-else>
      <v-col cols="12" style="border-bottom: 1px solid #d4dff0">
        <v-container fill-height class="ma-0 pa-0" style="min-height: 101px">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <h2 class="subscription-packages-item__price--package-price headline font-weight-bold text-center mb-0">
                {{ $t('general.tailored') }}
              </h2>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" class="py-5 text-center" style="border-bottom: 1px solid #d4dff0; min-height: 117px">
        <v-container fill-height>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12">
              <div class="subtitle-2 grey--text">
                {{ $t('pricing.special_plan_info') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" class="py-5 text-center">
        <v-btn color="primary" class="px-10" large @click="openSigniPricing">
          {{ $t('general.ask') }}
        </v-btn>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { goToLinkWithTarget } from '@/common/reusable/routing'

export default {
  name: 'SubscriptionSelectionCustomItem',
  computed: {
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    openSigniPricing() {
      return goToLinkWithTarget(this.$t('links.pricing'))
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-packages-item {
  background-color: #ffffff;
  border: 1px solid #d4dff0;
  border-radius: 8px;
  position: relative;

  &__favourite-package {
    border: 1px solid #33d380;
  }

  &__favourite-package--badge {
    position: absolute;
    top: -1rem;
  }

  &__price--package-price {
    color: #5e239e;
  }
}
</style>

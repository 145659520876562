<template>
  <v-col class="credit-packages-selection mx-auto">
    <v-row
      class="pa-3 pb-0"
      align="center"
      justify="space-between"
      :style="expandCreditPackages ? 'border-bottom: 1px solid #d4dff0' : ''"
    >
      <v-col cols="12" class="pb-0">
        <h2 class="headline font-weight-bold text-left mb-0">
          {{ $t('subscription.pay_as_you_go_plan') }}
        </h2>
      </v-col>

      <v-col cols="auto">
        <h4 class="font-weight-medium text-left grey--text mb-0">
          {{ $t('profile.credits.info', { credits: userCredits }) }}
        </h4>
      </v-col>

      <v-col class="col-12 col-md-auto pa-0 pb-1 pb-md-0 text-right">
        <v-btn color="primary" min-width="7.5rem" text @click="expandCreditPackages = !expandCreditPackages">
          {{ $t(expandCreditPackages ? 'contract.hide' : 'contract.buy') }}
          <v-icon>
            {{ `mdi-chevron-${expandCreditPackages ? 'up' : 'down'}` }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row v-show="expandCreditPackages" align="center" justify="center">
        <v-col v-if="!isLoading" cols="12">
          <v-row align="center" justify="center" class="pa-3" no-gutters>
            <v-col v-if="isDiscountVisible" cols="12" class="vat-info-credit text-left pb-5">
              <p class="action mb-0" v-html="$t('payments.credits.discount')"></p>
            </v-col>

            <v-col v-for="creditPackage in creditPackagesList" :key="creditPackage.id" class="col-12 pb-2">
              <CreditPackageItem :credit-package="creditPackage" />
            </v-col>

            <v-col cols="12" class="vat-info-credit pt-2 text-left">
              {{ $t('payments.price.dph') }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreditPackageItem from '@subscription/components/CreditPackageItem'

export default {
  name: 'CreditPackagesSelection',
  components: {
    CreditPackageItem,
  },
  data() {
    return {
      loaded: false,
      expandCreditPackages: false,
    }
  },
  computed: {
    ...mapGetters({
      creditPackagesList: 'creditPackages/list',
      isDiscountVisible: 'creditPackages/isDiscountVisible',
      documentsLeft: 'subscription/documentsLeft',
      userProfile: 'user/profile',
    }),
    isLoading() {
      return !this.loaded
    },
    userCredits() {
      const userAdditionalCredits = this.userProfile?.credits || 0
      const documentsLeft = this.documentsLeft || 0

      return documentsLeft < userAdditionalCredits ? userAdditionalCredits - documentsLeft : 0
    },
  },
  created() {
    window.dataLayer.push({ event: 'credit_buy_started' })

    this.loaded = false

    Promise.all([this.fetchCurrencies(), this.fetchCreditPackages()]).finally(() => {
      this.loaded = true
    })
  },
  methods: {
    ...mapActions({
      fetchCurrencies: 'currencies/fetchCurrencies',
      fetchCreditPackages: 'creditPackages/fetchCreditPackages',
    }),
  },
}
</script>

<style lang="scss" scoped>
.credit-packages-selection {
  background-color: #ffffffff;
  border: 1px solid #d4dff0;
  border-radius: 8px;

  .vat-info-credit {
    .action {
      color: #7435b8;
      line-height: 1.5;
    }
  }
}
</style>

<template>
  <v-row
    class="subscription-packages-item pa-2 fill-height"
    :class="{ 'subscription-packages-item__favourite-package': isFavourite }"
    align="stretch"
    justify="space-between"
    no-gutters
  >
    <template v-if="isMobileDevice">
      <v-col cols="12" style="border-bottom: 1px solid #d4dff0">
        <v-container fill-height>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12">
              <span class="subscription-packages-item__price--package-price title font-weight-bold">
                {{ subscriptionPackage.name }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" style="border-bottom: 1px solid #d4dff0">
        <v-container fill-height>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12">
              <span class="subscription-packages-item__price--package-price title font-weight-bold">
                {{ subscriptionPackage.credit }}
              </span>
              <span>
                {{
                  $t(
                    parseInt(subscriptionPackage.credit) > 1 ? 'general.documents' : 'contract.type.base_contract',
                  ).toLowerCase()
                }}/{{ $t(`pricing.${subscriptionPeriod}`).toLowerCase() }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12">
        <v-row class="py-2" align="center" justify="space-between" no-gutters>
          <v-col class="pl-2">
            <span class="subscription-packages-item__price--package-price title font-weight-bold">
              {{ subscriptionPackagePrice }}
            </span>

            <span>{{ $t(`pricing.${subscriptionPeriod}`).toLowerCase() }}</span>
          </v-col>

          <v-col cols="auto">
            <v-tooltip v-model="show" top :disabled="!isUnavailable">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn color="primary" :disabled="isUnavailable" @click="showConfirmDialog = true">
                    {{ $t('pricing.buy') }}
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('subscription.message.unavailable') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template v-else>
      <v-col cols="12" class="py-5 text-center" style="border-bottom: 1px solid #d4dff0">
        <v-row class="pa-2" align="center" justify="center">
          <v-col cols="12" class="pt-4 pb-0">
            <h3 class="subscription-packages-item__price--package-price title font-weight-bold text-center mb-0">
              {{ subscriptionPackage.name }}
            </h3>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="pb-2" style="border-bottom: 1px solid #d4dff0">
        <v-row class="pa-2" align="center" justify="center">
          <v-col cols="12" class="pt-8 pb-0">
            <h2 class="subscription-packages-item__price--package-price headline font-weight-bold text-center mb-0">
              {{ subscriptionPackage.credit }}
            </h2>
          </v-col>

          <v-col cols="12" class="pt-0 text-center">
            <span class="subtitle-2 font-weight-regular">
              {{
                $t(
                  parseInt(subscriptionPackage.credit) > 1 ? 'general.documents' : 'contract.type.base_contract',
                ).toLowerCase()
              }}/{{ $t(`pricing.${subscriptionPeriod}`).toLowerCase() }}
            </span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="py-5 text-center" style="border-bottom: 1px solid #d4dff0">
        <div class="subscription-packages-item__price--package-price title font-weight-bold">
          {{ subscriptionPackagePrice }}
        </div>

        <div class="subtitle-2 font-weight-regular">
          {{ $t(`pricing.${subscriptionPeriod}`).toLowerCase() }}
        </div>
      </v-col>

      <v-col cols="12" class="py-5 text-center">
        <v-tooltip v-model="show" top :disabled="!isUnavailable">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn color="primary" class="px-10" :disabled="isUnavailable" large @click="showConfirmDialog = true">
                {{ $t('pricing.buy') }}
              </v-btn>
            </div>
          </template>

          <span>{{ $t('subscription.message.unavailable') }}</span>
        </v-tooltip>
      </v-col>
    </template>

    <SigniModal v-model="showConfirmDialog" @close="showConfirmDialog = false">
      <template v-slot:title>
        {{ $t('payments.subscription.confirm') }}
      </template>

      <template v-slot:default>
        <p class="ma-0 mt-3 subscription-container__description">
          {{ $t('payments.subscription.confirm.price_info') }}
        </p>

        <p
          class="ma-0 mt-2 subscription-container__description"
          v-html="$t('payments.subscription.confirm.cancel_info')"
        ></p>

        <p
          class="ma-0 mt-2 subscription-container__description"
          v-html="$t('payments.subscription.confirm.questions_info')"
        ></p>

        <v-checkbox v-model="checkboxes" value="goPay" color="primary" class="ma-0 pa-0 mt-5">
          <template v-slot:label>
            <p class="mb-0 subscription-container__description">
              {{ $t('payments.subscription.confirm.gopay') }}
            </p>
          </template>
        </v-checkbox>

        <v-checkbox v-model="checkboxes" value="terms" color="primary" class="ma-0 pa-0">
          <template v-slot:label>
            <p class="mb-0 subscription-container__description">
              {{ $t('payments.subscription.confirm.term_part_1') }}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a target="_blank" :href="$t('links.terms_and_conditions.registered')" @click.stop v-on="on">
                    {{ $t('payments.subscription.confirm.term_part_2') }}
                  </a>
                </template>
                {{ $t('legal.terms') }}
              </v-tooltip>
            </p>
          </template>
        </v-checkbox>

        <div class="text-center">
          <v-btn
            color="primary"
            :disabled="!isAllChecked"
            :loading="buyingSubscriptionPackage"
            width="100%"
            max-width="12.5rem"
            x-large
            @click="buySubscriptionPackage"
          >
            {{ $t('pricing.buy') }}
          </v-btn>
        </div>
      </template>
    </SigniModal>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { goPayPaymentInit } from '@subscription/services/paymentService'
import { environment } from '@/config/environment'

export default {
  name: 'SubscriptionSelectionItem',
  components: {
    SigniModal: () => import('@/components/SigniModal'),
  },
  props: {
    subscriptionPackage: {
      type: Object,
      required: true,
    },
    subscriptionPeriod: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buyingSubscriptionPackage: false,
      checkboxes: ['goPay', 'terms'],
      showConfirmDialog: false,
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      currentCurrency: 'currencies/currentCurrency',
      hasActiveSubscription: 'subscription/hasActiveSubscription',
    }),
    isUnavailable() {
      return 'CZK' !== `${this.currentCurrency(this.subscriptionPackage.currency)?.code}`
    },
    isAllChecked() {
      return 2 === this.checkboxes.length
    },
    isFavourite() {
      return this.subscriptionPackage?.favorite || false
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile
    },
    currency() {
      return this.currentCurrency(this.subscriptionPackage?.currency)
    },
    documentPrice() {
      return `${this.oneDocumentPrice} ${this.currency?.symbol} / ${this.$t(
        'contract.type.base_contract',
      ).toLowerCase()}`
    },
    oneDocumentPrice() {
      let price = this.subscriptionPackage?.price / this.subscriptionPackage?.credit

      if (price % 10 > 0) {
        price = price.toFixed(
          2, //'EUR' === this.currentCurrency(this.subscriptionPackage.currency)?.code ? 2 : 0,
        )
      }

      return `${price}`.replace('.00', '') || 'XX'
    },
    subscriptionPackagePrice() {
      let price = this.subscriptionPackage?.price

      if (price % 10 > 0) {
        price = price.toFixed(0)
      }

      return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${this.currency?.symbol}`
    },
  },
  methods: {
    ...mapActions({
      cancelSubscription: 'subscription/cancelSubscription',
      fetchActive: 'subscription/fetchActive',
    }),
    async buySubscriptionPackage() {
      this.buyingSubscriptionPackage = true

      window.dataLayer.push({ event: 'subscription_buy_started' })

      this.fetchActive()
        .then(async () => {
          await this.cancelSubscription()
        })
        .catch(() => {})
        .finally(() => {
          goPayPaymentInit({
            data: {
              package_id: this.subscriptionPackage.id,
              return_url: `${environment.getAppUrl()}${this.$router.resolve({ name: 'subscription' }).href}`,
            },
          })
            .then((response) => {
              // eslint-disable-next-line no-undef
              _gopay.checkout(
                {
                  gatewayUrl: response.gw_url,
                  inline: false,
                },
                () => {},
              )
            })
            .catch((errorCode) => {
              if (422 === errorCode) {
                this.$notification.warning(this.$t('payments.billing_data.warning'))

                return this.$router.push({
                  name: 'subscription',
                  query: { action: 'addInvoiceData' },
                })
              }

              this.$notification.error(this.$t('payments.transaction.failed'))
              this.buyingSubscriptionPackage = false
            })
            .finally(() => {
              this.showConfirmDialog = false
            })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-packages-item {
  background-color: #ffffff;
  border: 1px solid #d4dff0;
  border-radius: 8px;
  position: relative;

  &__favourite-package {
    border: 1px solid #33d380;
  }

  &__favourite-package--badge {
    position: absolute;
    top: -1rem;
  }

  &__price--package-price {
    color: #5e239e;
  }
}
</style>

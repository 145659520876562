<template>
  <v-col class="subscription-packages-selection mx-auto pa-0 pt-5">
    <v-row align="center" justify="center">
      <v-col class="col-12">
        <h2 class="headline font-weight-bold text-left mb-0">
          {{ $t('subscription.select.info') }}
        </h2>
      </v-col>
    </v-row>

    <v-row align="start" justify="center">
      <v-col class="col-12 py-0 my-0">
        <v-radio-group v-model="subscriptionPeriod" class="my-0 pt-1 pb-5" hide-details row>
          <v-radio :label="$t('pricing.yearly')" value="yearly"></v-radio>
          <v-radio
            :label="`${$t('pricing.monthly').slice(0, 1).toUpperCase()}${$t('pricing.monthly').slice(1)}`"
            value="monthly"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col class="col-12 pt-0">
        <template v-if="isMobileDevice">
          <v-row align="center" justify="center">
            <v-col v-for="subscriptionPackage in filteredSubscriptionPackages" :key="subscriptionPackage.id" cols="12">
              <SubscriptionSelectionLoadingItem v-if="isLoading" />

              <template v-else>
                <SubscriptionSelectionCustomItem v-if="subscriptionPackage.custom" />

                <SubscriptionSelectionItem
                  v-else
                  :subscription-package="subscriptionPackage"
                  :subscription-period="subscriptionPeriod"
                />
              </template>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-row v-if="loading" class="fill-height" align="stretch" justify="start">
            <v-col v-for="index in 4" :key="index" cols="3">
              <SubscriptionSelectionLoadingItem />
            </v-col>
          </v-row>

          <v-row v-else align="center" justify="start">
            <v-col v-for="subscriptionPackage in filteredSubscriptionPackages" :key="subscriptionPackage.id" cols="3">
              <SubscriptionSelectionCustomItem v-if="subscriptionPackage.custom" />

              <SubscriptionSelectionItem
                v-else
                :subscription-package="subscriptionPackage"
                :subscription-period="subscriptionPeriod"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col class="col-12 vat-info-credit text-left">
        <span class="font-weight-medium">
          {{ $t('payments.price.dph') }}
        </span>
      </v-col>
    </v-row>

    <v-row class="mt-0" align="center" justify="space-between">
      <v-col class="col-12 col-md-6 text-left">
        <template v-if="filteredSubscriptionChunksArray.length > 1">
          <span class="font-weight-medium">
            {{ $t('subscription.pagination_link') }}
          </span>
          <a
            v-if="page !== 0"
            href="#"
            class="font-weight-bold"
            style="color: #5f23a0 !important"
            @click.prevent="page--"
          >
            {{ $t('general.less').toLowerCase() }}
          </a>
          <span v-if="page !== 0 && page !== filteredSubscriptionChunksArray.length - 1" class="font-weight-medium">
            /
          </span>
          <a
            v-if="page !== filteredSubscriptionChunksArray.length - 1"
            href="#"
            class="font-weight-bold"
            style="color: #5f23a0 !important"
            @click.prevent="page++"
          >
            {{ $t('general.more').toLowerCase() }}
          </a>
          <span class="font-weight-medium"> {{ $t('general.documents').toLowerCase() }}? </span>
        </template>
      </v-col>

      <v-col class="col-12 py-0 col-md-auto" :class="$vuetify.breakpoint.mobile ? 'text-center' : 'text-right'">
        <v-btn color="primary" class="pr-1" text href="mailto: sales@signi.com">
          <span class="text-decoration-underline">
            {{ $t('subscription.custom_offer_link') }}
          </span>
          <v-icon class="pl-1" small>mdi-open-in-new</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-0" align="center">
      <v-col class="col-12 vat-info-credit text-left">
        <span class="font-weight-medium" v-html="$t('payments.help_info')"></span>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { goToLinkWithTarget } from '@/common/reusable/routing'
import SubscriptionSelectionItem from '@subscription/components/SubscriptionSelectionItem'
import SubscriptionSelectionCustomItem from '@subscription/components/SubscriptionSelectionCustomItem'
import SubscriptionSelectionLoadingItem from '@subscription/components/SubscriptionSelectionLoadingItem'

export default {
  name: 'SubscriptionPackagesSelection',
  components: {
    SubscriptionSelectionLoadingItem,
    SubscriptionSelectionCustomItem,
    SubscriptionSelectionItem,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 0,
      loaded: false,
      selectedSubscriptionPackage: null,
      checkboxes: ['goPay', 'terms'],
      subscriptionPeriod: 'yearly',
      confirm: {
        data: null,
        show: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      subscriptionPackages: 'subscriptionPackages/list',
      profile: 'profile',
    }),
    filteredSubscriptionPackages() {
      const filteredSubscriptionPackages = this.subscriptionPackages?.filter((subscriptionPackage) => {
        return 'yearly' === this.subscriptionPeriod
          ? 1 !== subscriptionPackage?.repeat_payment_months
          : 12 !== subscriptionPackage?.repeat_payment_months
      })

      if (this.isLegalType) {
        return filteredSubscriptionPackages?.filter((subscriptionPackage) => 'home' !== subscriptionPackage?.type)
      }

      return filteredSubscriptionPackages
    },
    filteredSubscriptionChunksArray() {
      return this.reduceSubscriptionChunks(this.filteredSubscriptionPackages)
    },
    isLegalType() {
      return !!this.profile?.workspaces?.find((workspace) => 'legal' === workspace?.type && workspace?.is_owner)?.id
    },
    isLoading() {
      return !this.loaded
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  created() {
    this.loaded = false
    this.fetchCurrencies()
    this.fetchSubscriptionPackages()
      .then(() => {
        this.selectedSubscriptionPackage =
          this.subscriptionPackages[Math.floor(this.subscriptionPackages.length / 2)]?.id
      })
      .finally(() => {
        this.loaded = true
      })
  },
  methods: {
    ...mapActions({
      fetchCurrencies: 'currencies/fetchCurrencies',
      fetchSubscriptionPackages: 'subscriptionPackages/fetchSubscriptionPackages',
    }),
    openSigniPricing() {
      return goToLinkWithTarget(this.$t('links.pricing_table'))
    },
    reduceSubscriptionChunks(packages) {
      return (
        packages.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / 4)

          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
          }

          resultArray[chunkIndex].push(item)

          return resultArray
        }, []) || []
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-packages-selection {
  max-width: 972px;

  .vat-info-credit {
    //font-size: 0.6rem;
  }
}
</style>

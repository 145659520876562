<template>
  <Data :showMenu="false" :shadow="true" value>
    <v-container class="subscription mt-0 pt-0 px-0 px-md">
      <v-row class="subscription__section mx-auto" align="center">
        <v-col class="col-12 pt-0">
          <h1 class="font-weight-bold text-left mb-0">
            {{ $t('subscription.payments_and_subscriptions') }}
          </h1>
        </v-col>
      </v-row>

      <v-row ref="subscriptions" class="subscription__section mx-auto mb-5 mb-md-10" align="center" justify="center">
        <v-col class="subscription__packages col-12">
          <ActiveSubscription
            v-if="hasActiveSubscription"
            @on-change-subscription-package="changeSubscriptionPackage = !changeSubscriptionPackage"
          />

          <SubscriptionSelectionPackages
            v-if="!hasActiveSubscription || changeSubscriptionPackage"
            :loading="isLoading"
          />
        </v-col>
      </v-row>

      <v-row ref="credits" class="subscription__section mx-auto mb-5 mb-md-10" align="center" justify="center">
        <v-col class="subscription__credit-packages col-12">
          <CreditPackagesSelection />
        </v-col>
      </v-row>

      <v-row ref="billing" class="subscription__section mx-auto mb-5 mb-md-10" align="stretch" justify="center">
        <v-col class="subscription__billing--card col-12 col-md-6 pr-md-4">
          <SubscriptionPaymentCard />
        </v-col>

        <v-col class="subscription__billing--data col-12 col-md-6 pl-md-4">
          <SubscriptionBillingData v-model="editBillingData" />
        </v-col>
      </v-row>

      <v-row ref="invoices" class="subscription__section mx-auto mb-5 mb-md-10" align="stretch" justify="center">
        <v-col class="col-12">
          <SubscriptionInvoices />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog :value="successOrderDialog" max-width="500px">
      <v-card class="pa-5">
        <v-card-title class="py-4">
          <h2 class="title font-weight-medium mx-auto mb-0 text-center">
            {{ $t('payment.success_order.title') }}
          </h2>
        </v-card-title>

        <v-card-text class="success-order__dialog">
          <p class="subtitle-2" v-html="$t('payment.success_order.line1')"></p>

          <p class="subtitle-2" v-html="$t('payment.success_order.line2')"></p>

          <p class="subtitle-2" v-html="$t('payment.success_order.line3')"></p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" ref="confirm" min-width="30%" @click="onConfirmSuccessOrderDialog">
            {{ $t('general.continue') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Data>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getGoPayPaymentStatus } from '@subscription/services/paymentService'
import CreditPackagesSelection from '@subscription/components/CreditPackagesSelection'
import SubscriptionBillingData from '@subscription/components/SubscriptionBillingData'
import SubscriptionPaymentCard from '@subscription/components/SubscriptionPaymentCard'
import SubscriptionSelectionPackages from '@subscription/components/SubscriptionPackagesSelection'
import ActiveSubscription from '@subscription/components/ActiveSubscription'
import SubscriptionInvoices from '@subscription/components/SubscriptionInvoices'

export default {
  name: 'Subscription',
  components: {
    ActiveSubscription,
    CreditPackagesSelection,
    SubscriptionBillingData,
    SubscriptionPaymentCard,
    SubscriptionSelectionPackages,
    SubscriptionInvoices,
  },
  data() {
    return {
      changeSubscriptionPackage: false,
      editBillingData: false,
      loaded: false,
      successOrderDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      hasActiveSubscription: 'subscription/hasActiveSubscription',
      userProfile: 'user/profile',
    }),
    isLoading() {
      return !this.loaded
    },
    use() {
      return this.$route?.hash?.replace('#', '') || null
    },
  },
  watch: {
    loaded: {
      handler: function (state) {
        if (true === state && this.use !== null) {
          this.scrollTo(this.use)
        }
      },
    },
  },
  created() {
    const paymentId = this.$route?.query?.id || null

    if (paymentId !== null) {
      getGoPayPaymentStatus({ paymentId })
        .then((response) => {
          this.$store.dispatch('getUserProfile')

          if ('PAID' === response?.state) {
            if (response?.credit) {
              window.dataLayer.push({
                event: 'credit_buy_finished_' + response.credit.credit,
              })
            } else if (response.package) {
              // TODO - correct package name
              let packageName

              switch (parseInt(response?.package?.id)) {
                case 1:
                  packageName = 'basic'
                  break
                case 2:
                  packageName = 'favourite'
                  break
                case 3:
                  packageName = 'premium'
                  break
                default:
                  packageName = 'notSpecified'
              }

              window.dataLayer.push({
                event: 'credit_buy_finished_' + packageName,
              })
            }

            this.successOrderDialog = true
          } else {
            if (response.credit) {
              window.dataLayer.push({ event: 'credit_buy_failed' })
            } else {
              window.dataLayer.push({ event: 'subscription_buy_failed' })
            }

            this.$notification.error(this.$t('payments.transaction.failed'))
          }
        })
        .catch(() => {
          this.$notification.error(this.$t('payments.transaction.notFound'))
        })
    }
  },
  mounted() {
    Promise.all([
      this.fetchCountriesList(),
      this.fetchCurrencies(),
      this.fetchSubscriptionPackages(),
      this.fetchUserProfile(),
      this.fetchActiveSubscription(),
    ]).finally(() => {
      this.loaded = true
    })

    const self = this
    this.$nextTick(() => {
      setTimeout(() => {
        self.fetchUserProfile()
      }, 1000)
    })
  },
  methods: {
    ...mapActions({
      fetchActiveSubscription: 'subscription/fetchActive',
      fetchCountriesList: 'countries/fetchCountriesList',
      fetchCurrencies: 'currencies/fetchCurrencies',
      fetchSubscriptionPackages: 'subscriptionPackages/fetchSubscriptionPackages',
      fetchUserProfile: 'user/fetchProfile',
    }),
    scrollTo(elementRef) {
      this.$nextTick(() => {
        const el = this.$refs[elementRef]

        if ('billing' === elementRef) {
          this.editBillingData = true
        }

        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      })
    },
    onConfirmSuccessOrderDialog() {
      this.successOrderDialog = false
      this.$router.replace({
        query: {},
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription {
  max-width: 1250px;
  width: 100%;

  &__section {
    max-width: 972px;
    width: 100%;
  }
}

:global(.success-order__dialog a) {
  font: inherit !important;
}
</style>

<template>
  <v-data-table :headers="headers" :items="invoices" :items-per-page="10" :loading="isLoading">
    <template v-slot:item.flexi_bee_id="{ item }">
      <v-icon color="#B4C1D6" left>mdi-file-outline</v-icon>
      {{ $t('subscription.invoice_title', { invoiceId: item.flexi_bee_id }) }}
    </template>

    <template v-slot:item.issue_date="{ item }">
      {{ `${getFormattedDate(item.issue_date)}` }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn color="primary" class="text-decoration-underline" text @click="downloadInvoice(item.flexi_bee_id)">
        {{ $t('general.download') }}
      </v-btn>

      <v-overlay v-if="overlay" :value="overlay">
        <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </template>

    <template v-slot:loading>
      <div class="my-5">
        <span class="subtitle-2">{{ `${$t('contract.search.loading')}...` }}</span>
      </div>
    </template>

    <template v-slot:no-data>
      <div class="my-5">
        <span class="subtitle-2">{{ $t('subscription.no_invoices') }}</span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import FileSaver from 'file-saver'
import { mapActions, mapGetters } from 'vuex'
import { internalApiRequest } from '@/shared/services/apiService'
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions'

export default {
  name: 'SubscriptionInvoices',
  data() {
    return {
      loaded: true,
      overlay: false,
      headers: [
        {
          text: this.$t('general.title'),
          align: 'start',
          sortable: false,
          value: 'flexi_bee_id',
          width: '40%',
        },
        {
          text: this.$t('contract.date'),
          align: 'start',
          sortable: false,
          value: 'issue_date',
          width: '30%',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      invoices: 'subscriptionInvoices/list',
    }),
    isLoading() {
      return !this.loaded
    },
  },
  created() {
    this.loaded = false
    this.fetchInvoices().finally(() => {
      this.loaded = true
    })
  },
  methods: {
    ...mapActions({
      fetchInvoices: 'subscriptionInvoices/fetchInvoices',
    }),
    getFormattedDate(date) {
      return getFormattedDateByLocale({ date })
    },
    downloadInvoice(invoiceId) {
      this.overlay = true

      internalApiRequest({
        method: 'GET',
        responseType: 'blob',
        path: `api/v2/loggedUser/invoice/${invoiceId}/pdf`,
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf',
        },
      }).then((blobData) => {
        FileSaver.saveAs(blobData, `${this.$t('subscription.invoice_title', { invoiceId: invoiceId })}.pdf`)

        this.overlay = false
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <v-col class="subscription-billing-data">
    <v-row align="end" justify="start">
      <v-col class="col-auto px-0">
        <h2 class="headline font-weight-bold text-left mb-0">
          {{ $t('payments.info') }}
        </h2>
      </v-col>

      <v-col class="col-auto px-1">
        <v-btn color="primary" class="text-decoration-underline px-1" text small @click="billingDataEdit = true">
          {{ $t('general.edit') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="subscription-billing-data__billing-data py-2 px-4 py-md-6 px-md-8" align="center" justify="start">
      <v-col v-for="item in billingData" :key="item.title" cols="12" class="pt-1 px-0 pb-0">
        <v-row align="stretch" justify="start" no-gutters>
          <v-col class="col-6 text-left grey--text">
            <span>{{ $t(item.title) }}:</span>
          </v-col>

          <v-col class="col-6 d-inline-block text-truncate text-left black--text">
            {{ item.value }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <BillingDataForm v-model="billingDataEdit" />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import BillingDataForm from '@subscription/components/BillingDataForm'

export default {
  name: 'SubscriptionBillingData',
  components: {
    BillingDataForm,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/profile',
    }),
    billingDataEdit: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    billingCompany() {
      return this.userProfile?.invoice_info?.invoice_organization_name || ''
    },
    billingFullName() {
      return `${this.userProfile?.invoice_info?.invoice_firstname || ''} ${
        this.userProfile?.invoice_info?.invoice_lastname || ''
      }`
    },
    billingStreet() {
      return this.userProfile?.invoice_info?.invoice_street || ''
    },
    billingCity() {
      return this.userProfile?.invoice_info?.invoice_city || ''
    },
    billingZip() {
      return this.userProfile?.invoice_info?.invoice_zip || ''
    },
    billingIco() {
      return this.userProfile?.invoice_info?.invoice_ico || ''
    },
    billingDic() {
      return this.userProfile?.invoice_info?.invoice_dic || ''
    },
    billingEmail() {
      return this.userProfile?.invoice_info?.invoice_email || ''
    },
    billingData() {
      return [
        { title: 'general.company', value: this.billingCompany },
        { title: 'general.name_and_surname', value: this.billingFullName },
        { title: 'company.street_and_number', value: this.billingStreet },
        { title: 'company.city', value: this.billingCity },
        { title: 'company.zip', value: this.billingZip },
        { title: 'company.ico', value: this.billingIco },
        { title: 'company.dic', value: this.billingDic },
        { title: 'general.email_simple', value: this.billingEmail },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-billing-data {
  &__billing-data {
    background-color: #ffffff;
    border: 1px solid #d4dff0;
    border-radius: 8px;
  }
}
</style>

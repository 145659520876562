<template>
  <SigniModal v-model="billingDataEdit" @close="closeBillingDataDialog">
    <template v-slot:title>
      {{ $t('payments.info') }}
    </template>

    <form ref="billingData" class="pt-5" @submit.prevent="saveBillingData">
      <v-text-field
        type="text"
        name="organization-name"
        v-model="billingData.invoice_organization_name"
        :label="$t('company.title2')"
        :error="$v.billingData.invoice_organization_name.$error || !$v.billingData.invoice_organization_name.valid"
        :hint="!$v.billingData.invoice_organization_name.valid ? $t('error.unsupported_chars') : ''"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_organization_name.required" color="error">
            mdi-alert-circle-outline
          </v-icon>
        </template>

        <template v-slot:message>
          <span style="color: #e53935">
            {{ $t('error.unsupported_chars') }}
          </span>
        </template>
      </v-text-field>

      <v-text-field
        type="text"
        name="firstname"
        v-model="billingData.invoice_firstname"
        :label="$t('general.name')"
        :error="$v.billingData.invoice_firstname.$error || !$v.billingData.invoice_firstname.valid"
        :hint="!$v.billingData.invoice_firstname.valid ? $t('error.unsupported_chars') : ''"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_firstname.required" color="error"> mdi-alert-circle-outline </v-icon>
        </template>

        <template v-slot:message>
          <span style="color: #e53935">
            {{ $t('error.unsupported_chars') }}
          </span>
        </template>
      </v-text-field>

      <v-text-field
        type="text"
        name="surname"
        v-model="billingData.invoice_lastname"
        :label="$t('general.surname')"
        :error="$v.billingData.invoice_lastname.$error || !$v.billingData.invoice_lastname.valid"
        :hint="!$v.billingData.invoice_lastname.valid ? $t('error.unsupported_chars') : ''"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_lastname.required" color="error"> mdi-alert-circle-outline </v-icon>
        </template>

        <template v-slot:message>
          <span style="color: #e53935">
            {{ $t('error.unsupported_chars') }}
          </span>
        </template>
      </v-text-field>

      <v-text-field
        type="email"
        name="email"
        v-model="billingData.invoice_email"
        :label="$t('general.email_simple')"
        :error="$v.billingData.invoice_email.$error"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_email.required" color="error"> mdi-alert-circle-outline </v-icon>
        </template>
      </v-text-field>

      <v-text-field
        type="text"
        name="organization-ico"
        v-model="billingData.invoice_ico"
        :label="$t('company.ico')"
        :error="$v.billingData.invoice_ico.$error"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_ico.required" color="error"> mdi-alert-circle-outline </v-icon>
        </template>
      </v-text-field>

      <v-text-field
        type="text"
        name="organization-dic"
        v-model="billingData.invoice_dic"
        :label="$t('company.dic')"
        :error="$v.billingData.invoice_dic.$error"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_dic.required" color="error"> mdi-alert-circle-outline </v-icon>
        </template>
      </v-text-field>

      <v-text-field
        type="text"
        name="street"
        v-model="billingData.invoice_street"
        :label="$t('company.address')"
        :error="$v.billingData.invoice_street.$error"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_street.required" color="error"> mdi-alert-circle-outline </v-icon>
        </template>
      </v-text-field>

      <v-text-field
        type="text"
        name="city"
        v-model="billingData.invoice_city"
        :label="$t('company.city')"
        :error="$v.billingData.invoice_city.$error || !$v.billingData.invoice_city.valid"
        :hint="!$v.billingData.invoice_city.valid ? $t('error.unsupported_chars') : ''"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_city.required" color="error"> mdi-alert-circle-outline </v-icon>
        </template>

        <template v-slot:message>
          <span style="color: #e53935">
            {{ $t('error.unsupported_chars') }}
          </span>
        </template>
      </v-text-field>

      <v-text-field
        type="text"
        name="postcode"
        v-model="billingData.invoice_zip"
        :label="$t('company.zip')"
        :error="$v.billingData.invoice_zip.$error || !$v.billingData.invoice_zip.valid"
        :hint="!$v.billingData.invoice_zip.valid ? $t('error.unsupported_chars') : ''"
        outlined
        @change="$v.billingData.$touch()"
      >
        <template v-slot:append>
          <v-icon v-if="!$v.billingData.invoice_zip.required" color="error"> mdi-alert-circle-outline </v-icon>
        </template>

        <template v-slot:message>
          <span style="color: #e53935">
            {{ $t('error.unsupported_chars') }}
          </span>
        </template>
      </v-text-field>

      <CountrySelect v-model="billingData.invoice_country" required />

      <v-btn type="submit" color="primary" x-large block>
        {{ $t('general.save') }}
      </v-btn>
    </form>
  </SigniModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { updateBillingData } from '@subscription/services/billingDataService'
import { getErrorMessage } from '@/common/reusable/errorResponse.js'
import { email, minLength, required, requiredIf } from 'vuelidate/lib/validators'
import SigniModal from '@/components/SigniModal'
import CountrySelect from '@subscription/components/CountrySelect'

export default {
  name: 'BillingDataForm',
  components: {
    CountrySelect,
    SigniModal,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      billingData: {},
    }
  },
  validations: {
    billingData: {
      invoice_organization_name: {
        minLength: minLength(2),
        required: requiredIf(function () {
          return this.isCompany
        }),
        valid: function () {
          if (this.billingData.invoice_organization_name) {
            return (
              this.billingData.invoice_organization_name.match(
                // eslint-disable-next-line no-useless-escape
                /^[^#$%\^&*!+@#$~°;\{\}=´\[\]\\<>\/\\\\]+$/gm,
              )?.length > 0
            )
          }

          return true
        },
      },
      invoice_firstname: {
        minLength: minLength(1),
        required: requiredIf(function () {
          return !this.isCompany
        }),
        valid: function () {
          if (this.billingData.invoice_firstname) {
            return (
              this.billingData.invoice_firstname.match(
                // eslint-disable-next-line no-useless-escape
                /^[^#$%\^&*!+@#$~°;\{\}=´\[\]\\<>\/\\\\]+$/gm,
              )?.length > 0
            )
          }

          return true
        },
      },
      invoice_lastname: {
        minLength: minLength(1),
        required: requiredIf(function () {
          return !this.isCompany
        }),
        valid: function () {
          if (this.billingData.invoice_lastname) {
            return (
              this.billingData.invoice_lastname.match(
                // eslint-disable-next-line no-useless-escape
                /^[^#$%\^&*!+@#$~°;\{\}=´\[\]\\<>\/\\\\]+$/gm,
              )?.length > 0
            )
          }

          return true
        },
      },
      invoice_ico: {
        required: requiredIf(function () {
          return this.isCompany
        }),
      },
      invoice_dic: {
        minLength: minLength(5),
        required: false,
      },
      invoice_street: {
        minLength: minLength(2),
        required: false,
      },
      invoice_city: {
        minLength: minLength(2),
        required: false,
        valid: function () {
          if (this.billingData.invoice_city) {
            return (
              this.billingData.invoice_city.match(
                // eslint-disable-next-line no-useless-escape
                /^[^#$%\^&*!+@#$~°;\{\}=´\[\]\\<>\/\\\\]+$/gm,
              )?.length > 0
            )
          }

          return true
        },
      },
      invoice_zip: {
        minLength: minLength(4),
        required: false,
        valid: function () {
          if (this.billingData.invoice_zip) {
            return (
              this.billingData.invoice_zip.match(
                // eslint-disable-next-line no-useless-escape
                /^[^#$%\^&*!+@#$~°;\{\}=´\[\]\\<>\/\\\\]+$/gm,
              )?.length > 0
            )
          }

          return true
        },
      },
      invoice_country: {
        required,
      },
      invoice_email: {
        email,
        required: false,
      },
    },
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
    }),
    billingDataEdit: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    isCompany() {
      return !!this.billingData?.invoice_organization_name
    },
  },
  watch: {
    profile: {
      handler: function (state) {
        this.billingData = {
          ...state.invoice_info,
        }
      },
    },
  },
  mounted() {
    this.billingData = {
      ...this.profile.invoice_info,
    }
  },
  methods: {
    ...mapActions({
      fetchCreditPackages: 'creditPackages/fetchCreditPackages',
      fetchProfile: 'user/fetchProfile',
      fetchSubscriptionPackages: 'subscriptionPackages/fetchSubscriptionPackages',
    }),
    saveBillingData() {
      this.$v.billingData.$touch()

      if (this.$v.billingData.$error) {
        this.$notification.error(this.$t('general.fill_required'))

        return
      }

      updateBillingData({ profileId: this.profile.id, data: this.billingData })
        .then(() => {
          this.$notification.success(this.$t('payments.info.edited'))
        })
        .catch((err) => {
          this.$notification.error(getErrorMessage({ errorCode: err.errorCode }))
        })
        .finally(() => {
          this.fetchProfile()
          this.fetchCreditPackages()
          this.fetchSubscriptionPackages()
          this.billingDataEdit = false
        })
    },
    closeBillingDataDialog() {
      this.billingDataEdit = false
      this.billingData = {
        ...this.profile.invoice_info,
      }

      if (this.$route.hash) {
        this.$router.push({
          name: 'subscription',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
